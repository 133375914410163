import React, { useState, useEffect } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import { useGetCurrentMembershipPeriodsQuery } from "../../../api/membershipPeriodSlice";
import { useGetMemberMembershipPeriodsByIdQuery } from "../../../api/memberMembershipPeriodSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box } from "@mui/system";
import { useGetExecutiveBodiessQuery } from "../../../api/executiveBodiesSlice";
import MemberSkeleton from "./skeletons/memberSkeleton";

const ExecutiveMember = () => {
  return (
    <>
      <Header />
      <ExecutiveMemberPage />
      <Footer />
    </>
  );
};

const ExecutiveMemberPage = () => {
  const data = useGetExecutiveBodiessQuery();


  return (
    <div>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <h2 className="text-white">Meet Our</h2>
                <h1 className="text-capitalize mb-5 text-lg">
                  Kathmandu Executive Bodies
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section team">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="mb-4">Meet Our Kathmandu Executive Bodies</h2>
                <div className="divider mx-auto my-4"></div>
              </div>
            </div>
          </div>

          <div className="row">
            {data !== undefined ? (
              <>
                {data.status === "fulfilled" ? (
                  <>
                    {data.data.ids.length !== 0 ? (
                      <>
                        {data.data.ids.map((key) => (
                          <div className="col-lg-3 col-md-6 col-sm-6" key={key}>
                            <div class="member">
                              <img
                                src={data.data.entities[key].image}
                                class="img-fluid"
                                alt=""
                              />
                              <div class="member-info">
                                <div class="member-info-content">
                                  <h4>{data.data.entities[key].name}</h4>
                                  <span>{data.data.entities[key].role}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <MemberSkeleton />
                    )}
                  </>
                ) : (
                  <MemberSkeleton />
                )}
              </>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExecutiveMember;
