import { Grid, List, ListItem, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TbBell } from "react-icons/tb";
import { FiMonitor, FiSettings, FiUsers } from "react-icons/fi";
import { MdLocalHospital, MdLogout, MdOutlineFormatListNumberedRtl, MdOutlinePayment, MdPayment } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlineDatabase, AiOutlineHome } from "react-icons/ai";
import { FcAbout } from "react-icons/fc";
import { TfiGallery } from "react-icons/tfi";
import { BsArrowLeft } from "react-icons/bs";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const Header = ({ page }) => {
  const history = useNavigate();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const Logout = (e) => {
    e.preventDefault();
    try {
      Cookies.remove("access");
      Cookies.remove("refresh");
      Cookies.remove("username");
      Cookies.remove("user_role");
      setSuccesMessege("Logout Successfully.");
      setTimeout(() => {
        history("/");
      }, [3000]);
    } catch (err) {
      setErrorMessege("Some Error Occur. Please Try again later.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };

  const [Show, setShow] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = (e, id) => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);


  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (Cookies.get("user_role") === 'admin') {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  const HomePage = ()=>{
    history('/')
  }
  return (
    <>
      <Box className="res-display" style={{ padding: "1.5rem" }}>
        <Box>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="dashboard-header-text">Dashboard</Typography>
            </Grid>
            <Grid item xs={2}>
              <Box style={{ textAlign: "center" }}>
                <Typography onClick={HomePage} style={{ fontSize: '15px', color: 'white', cursor: 'pointer' }}>
                  <BsArrowLeft
                    color="white"
                    style={{ marginRight: "7px" }}
                    size={22}
                  />
                  Back to home
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1} style={{ textAlign: "end" }}>
              <Grid container spacing={0}>
                <Grid xs={4} item>
                </Grid>
                <Grid xs={4} item>
                </Grid>
                <Grid item>
                  <Box style={{ textAlign: "right" }}>
                    <Typography onClick={Logout} style={{ fontSize: '15px', color: 'white', cursor: 'pointer' }}>
                      Logout
                      <MdLogout
                        color="white"
                        style={{ marginLeft: "12px" }}
                        size={19}
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="res-header-design res-display-block" style={{ display: 'none', zIndex: 2, }} component={Paper}>
        <Grid container>
          <Grid item xs={4}>
            <Box style={{ textAlign: "left" }}>
              <Typography onClick={handleOpen} style={{ fontSize: '15px', color: 'white', cursor: 'pointer' }}>
                <FaBars
                  color="white"
                  style={{ marginLeft: "12px" }}
                  size={22}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
              <Box style={{ textAlign: "center" }}>
                <Typography onClick={HomePage} style={{ fontSize: '15px', color: 'white', cursor: 'pointer' }}>
                  <BsArrowLeft
                    color="white"
                    style={{ marginRight: "7px" }}
                    size={22}
                  />
                  Back to home
                </Typography>
              </Box>
            </Grid>
          <Grid item xs={4}>
            <Box style={{ textAlign: "right" }}>
              <Typography onClick={Logout} style={{ fontSize: '15px', color: 'white', cursor: 'pointer' }}>
                Logout
                <MdLogout
                  color="white"
                  style={{ marginLeft: "12px" }}
                  size={19}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        // className="modal-background-design"
        >
          <Box className="modal-background-design">
            <Box style={{ padding: ".8rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FaUserCircle size={30} color="white" />
                </Grid>
                <Grid item xs={10}>
                  {isAuth === true ? (
                    <Typography className="admin-dashboard-text">
                      Admin Dashboard
                    </Typography>
                  ) : (
                    <Typography className="admin-dashboard-text">
                      Member Dashboard
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box style={{ marginTop: ".7rem" }}>
              {isAuth === true ? (
                <List>
                  <ListItem
                    className={
                      Show === "true"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("true")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/home"
                    >
                      <FiMonitor
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Dashboard
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "basic"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("basic")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/basic/data"
                    >
                      <AiOutlineHome
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Home Page Data
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "about"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("about")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/about"
                    >
                      <FcAbout
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      About
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "lf"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("lf")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to={`/dashboard/lf/number/?page=${page}`}
                    >
                      <MdOutlineFormatListNumberedRtl
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      LF Number
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "other"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("other")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/all/other/data"
                    >
                      <AiOutlineDatabase
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      All Other Data
                    </Link>
                  </ListItem>

                  <ListItem
                    className={
                      Show === "hospitals"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("hospitals")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/hospital/information"
                    >
                      <MdLocalHospital
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Hospital Informations
                    </Link>
                  </ListItem>
                  {/* <ListItem
                className={
                  Show === "member"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("member")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/membership-periods"
                >
                  <TfiGallery
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Membership Periods
                </Link>
              </ListItem> */}
                  <ListItem
                    className={
                      Show === "booking"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("booking")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/edit-bookings"
                    >
                      <TfiGallery
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Edit Bookings
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "life"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("life")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/life/time/Member"
                    >
                      <FiUsers
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Life Time Member
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "gallery"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("gallery")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/users"
                    >
                      <TfiGallery
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      User List
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "gallery"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("gallery")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/card-details"
                    >
                      <TfiGallery
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Card Details
                    </Link>
                  </ListItem>
                </List>
              ) : (
                <List>
                  <ListItem
                    className={
                      Show === "gallery"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("gallery")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/edit-bookings-user"
                    >
                      <TfiGallery
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Edit Bookings User
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "payment"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("payment")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/member/payment"
                    >
                      <MdPayment
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Membership Payment
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "paymentlist"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("paymentlist")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/member/payment/list"
                    >
                      <MdOutlinePayment
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Payment List
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "member_design"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("member_design")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/member/edit/data"
                    >
                      <FiUsers
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Edit Member Details
                    </Link>
                  </ListItem>
                  <ListItem
                    className={
                      Show === "new_design"
                        ? "dashboard-side-bar-link"
                        : "dashboard-side-bar-link1"
                    }
                    onClick={() => setShow("new_design")}
                  >
                    <Link
                      className="dashboard-side-bar-link-text"
                      to="/dashboard/book-my-hospitals"
                    >
                      <FiUsers
                        style={{
                          marginRight: "10px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                      Book my Hospital
                    </Link>
                  </ListItem>
                </List>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Header;
