import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import {
  Grid,
  Pagination,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import { Box } from "@mui/system";
import {
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useGetBlogPaginationQuery,
} from "../../../../../api/blogSlice";
import usePagination from "../../../../../components/reuseable/pagination";
import {
  useDeleteLFNoPageMutation,
  useGetLFNoPaginationQuery,
} from "../../../../../api/lfNoSlice";
import Controls from "../forms/controls/Controls";
import { useSearchFliterPageMutation } from "../../../../../api/searchApiSlice";
import { useForm1 } from "../../../../../components/forms/useForm";
import { Table } from "react-bootstrap";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import LfNoButton from "./lfNoButton";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";

const LfNoDashboardPage = () => {

  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardLfPagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardLfPagesPages = () => {
  const pageNo = useLocation();
  const paramPairs = decodeURIComponent(pageNo.search.substr(1));
  const paramPairs1 = paramPairs.split('=');
  console.log(paramPairs1);
  const pageNumber = paramPairs1[1];

  const [pageId, setPageId] = useState();
  useEffect(() => {
    if (pageNumber !== undefined) {
      console.log("yeha kina gayena")
      setPageId(pageNumber)
    } else {
      setPageId(1)
    }
  }, [pageNumber])
  const history = useNavigate();


  const { data, isLoading, isSuccess } = useGetLFNoPaginationQuery(pageId);


  const [deleteLfNoPage] = useDeleteLFNoPageMutation();

  const deleteConfirm = (e, id) => {

    e.preventDefault();
    deleteLfNoPage({ id });
    setTimeout(() => {
      window.location.reload(true);
    }, [2000])
  };

  let headers = [];
  let tableData = [];
  let content;

  var count;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "S.No.",
      "Member Name",
      "Lf Number",
      "Membership Number",
      "Company Name",
    ];
    data.results.forEach((key, index) => {
      tableData.push({
        "S.No.": index + 1,
        "Member Name": key.member_name,
        id: key.id,
        "Lf Number": key.lf_no,
        "Membership Number": key.nmsra_no,
        "Company Name": key.company_name,
      });
    });
    var newData = data.count / 10;
    count = Math.ceil(newData);
  }

  const [page, setPage] = useState(1);
  console.log(page);
  const PER_PAGE = 10;

  const _DATA = usePagination(tableData, count, PER_PAGE);


  const handleChange = (e, p) => {
    _DATA.jump(p);
    setPage(p)
    // const queryParams = new URLSearchParams(p).toString();
    history(`/dashboard/lf/number/?pageNumber=${p}`)
  };

  // useEffect(()=>{
  //   setPage(pageId);
  //  
  //   setPageId(pageId);
  // },[pageId])

  const [NMSRANO, results] = useSearchFliterPageMutation();

  const initialFValues = {
    search: "",
  };

  const { values, handleSearchClick } = useForm1(initialFValues);

  useEffect(() => {
    NMSRANO(values);
  }, [values]);

  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={7}>
            <Typography className="dashboard-home-page-text">
              LF Number Lists
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Controls.Search
                style={{ width: "100%" }}
                value={values.search}
                label="Search by NMSRA Number"
                name="search"
                onChange={handleSearchClick}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Link
              to="/dashboard/add/lfno"
              style={{ textDecoration: "none" }}
            >
              <LfNoButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          {values.search === "" ? (
            <>
              <BasicTable
                headers={headers}
                data={tableData}
                deleteColor={"#f50057"}
                isEdit={true}
                editColor={"#3f51b5"}
                isDelete={true}
                addColor={""}
                tableName={"lfno"}
                deleteConfirm={deleteConfirm}
                activeSign={["Active"]}
              />
              <Box
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingLeft: "1rem",
                }}
              >
                <Stack spacing={2} style={{ textAlign: "center" }}>
                  <Pagination
                    count={count}
                    size="small"
                    page={parseInt(pageNumber)}
                    variant="outlined"
                    onChange={handleChange}
                    color="primary"
                  />
                </Stack>
              </Box>
            </>
          ) : (
            <TableContainer style={{ borderTop: "none" }}>
              <Table
                sx={{ minWidth: 650 }}
                style={{ borderTop: "none" }}
                aria-label="simple table"
              >
                <TableHead style={{ borderTop: "none" }}>
                  <TableCell align="center">
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      S.No.
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Member Name
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Lf Number
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Membership Number
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Company Name
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography style={{ fontSize: "15px", fontWeight: "600" }}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  {results !== undefined ? (
                    <>
                      {results.status === "fulfilled" ? (
                        <>
                          {results.data.map((key, index) => (
                            <TableRow>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {key.member_name}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {key.lf_no}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {key.nmsra_no}
                              </TableCell>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {key.company_name}
                              </TableCell>
                              <TableCell align="center">
                                <Link to={`/dashboard/lfno/edit/${key.id}`}>
                                  <ModeEditSharpIcon
                                    sx={{ m: 0.5 }}
                                    style={{ color: "#3f51b5" }}
                                  />
                                </Link>
                                <a onClick={(e) => deleteConfirm(e, key.id)}>
                                  <DeleteSharpIcon
                                    sx={{ m: 0.5 }}
                                    style={{ color: '#f50057' }}
                                  />
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  );
};
export default LfNoDashboardPage;
