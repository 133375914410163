import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Box,
} from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Select(props) {
  const { name, label, value, error = null, options, onChange } = props;
  return (
    <FormControl variant="outlined" style={{ width: "100%", marginTop: '-0px', marginLeft: '0px' }} {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        style={{
          borderBlockColor: "white",
          width: "100%",
          textAlign: "start",
          marginBottom: '0px',
          margin: '0px',
          padding: '0px',
        }}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
