import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function DatePickers(props) {
  const { name, label, value, onChange, excludeDates, selectedDate, highlightedDates, modifierStyles } = props;



  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const handleDateChange = (e) => {

  };

  

  return (
    <DatePicker
      excludeDates={excludeDates}
      //   placeholderText="DD/MM/YYYY"
      selected={selectedDate}
      // dateFormat="dd/MM/yyyy"
      onSelect={(e) => onChange(convertToDefEventPara(name, e))} //when day is clicked
      onChange={handleDateChange} //only when value has changed
      className="new-date-picker-design"
      highlightDates={highlightedDates}
      modifierStyles={modifierStyles}
    />
  );
}
