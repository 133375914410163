import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { FiMonitor, FiUsers } from "react-icons/fi";
import {
  AiOutlineDatabase,
  AiOutlineHome,
  AiOutlineNumber,
} from "react-icons/ai";
import {
  MdLocalHospital,
  MdOutlineMiscellaneousServices,
  MdOutlineLocalActivity,
  MdEvent,
  MdPayment,
  MdOutlinePayment,
  MdOutlineFormatListNumberedRtl,
} from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import { GiNewspaper } from "react-icons/gi";
import { TfiGallery } from "react-icons/tfi";
import { FcAbout } from "react-icons/fc";
import { FaBlogger } from "react-icons/fa";
import "../../../../static/css/dashboard.css";
import { BsNewspaper } from "react-icons/bs";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const Sidebar = () => {
  const [Show, setShow] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const access = Cookies.get("access");
  const refresh = Cookies.get("refresh");
  const email = Cookies.get("email");
  const is_staff = Cookies.get("is_admin");
  const TOKEN_KEY = { access, refresh, email, is_staff };

  useEffect(() => {
    if (Cookies.get("user_role") === 'admin') {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  const decode_access = jwtDecode(access);

  return (
    <Box className="dashboard-side-bar-color res-display">
      <Box>
        <Box style={{ padding: ".8rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FaUserCircle size={30} color="white" />
            </Grid>
            <Grid item xs={10}>
              {isAuth === true ? (
                <Typography className="admin-dashboard-text">
                  Admin Dashboard
                </Typography>
              ) : (
                <Typography className="admin-dashboard-text">
                  Member Dashboard
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: ".7rem" }}>
          {isAuth === true ? (
            <List>
              <ListItem
                className={
                  Show === "true"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("true")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/home"
                >
                  <FiMonitor
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Dashboard
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "basic"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("basic")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/basic/data"
                >
                  <AiOutlineHome
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Home Page Data
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "about"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("about")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/about"
                >
                  <FcAbout
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  About
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "lf"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("lf")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to={`/dashboard/lf/number/`}
                >
                  <MdOutlineFormatListNumberedRtl
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  LF Number
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "other"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("other")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/all/other/data"
                >
                  <AiOutlineDatabase
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  All Other Data
                </Link>
              </ListItem>

              <ListItem
                className={
                  Show === "hospitals"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("hospitals")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/hospital/information"
                >
                  <MdLocalHospital
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Hospital Informations
                </Link>
              </ListItem>
              {/* <ListItem
                className={
                  Show === "member"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("member")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/membership-periods"
                >
                  <TfiGallery
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Membership Periods
                </Link>
              </ListItem> */}
              <ListItem
                className={
                  Show === "booking"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("booking")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/edit-bookings"
                >
                  <TfiGallery
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Edit Booking
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "life"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("life")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/life/time/Member"
                >
                  <FiUsers
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Life Time Member
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "gallery"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("gallery")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/users"
                >
                  <TfiGallery
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  User List
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "gallery"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("gallery")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/card-details"
                >
                  <TfiGallery
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Card Details
                </Link>
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem
                className={
                  Show === "gallery"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("gallery")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/edit-bookings-user"
                >
                  <TfiGallery
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Edit Booking User
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "payment"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("payment")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/member/payment"
                >
                  <MdPayment
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Membership Payment
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "paymentlist"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("paymentlist")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/member/payment/list"
                >
                  <MdOutlinePayment
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Payment List
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "member_design"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("member_design")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/member/edit/data"
                >
                  <FiUsers
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Edit Member Details
                </Link>
              </ListItem>
              <ListItem
                className={
                  Show === "new_design"
                    ? "dashboard-side-bar-link"
                    : "dashboard-side-bar-link1"
                }
                onClick={() => setShow("new_design")}
              >
                <Link
                  className="dashboard-side-bar-link-text"
                  to="/dashboard/book-my-hospitals"
                >
                  <FiUsers
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "3px",
                    }}
                  />
                  Book my Hospital
                </Link>
              </ListItem>
            </List>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
