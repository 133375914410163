import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useGetMemberRegistrationsByIdQuery } from "../../../api/memberRegistrationSlice";
import { useGetMrIDByAccessTokenQuery } from "../../../api/userSlice";
import Logo from "../../../static/image/logo.png";

const Header = () => {
  const [isAuth, setIsAuth] = useState(false);

  const access = Cookies.get("access");
  const refresh = Cookies.get("refresh");
  const email = Cookies.get("user_emailemail");
  const TOKEN_KEY = { access, refresh, email };
  const admin = Cookies.get('user_role');

  useEffect(() => {
    if (
      TOKEN_KEY["access"] == null &&
      TOKEN_KEY["refresh"] == null &&
      TOKEN_KEY["user_email"] == null
    ) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, []);

  const history = useNavigate();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const Logout = (e) => {
    e.preventDefault();
    try {
      Cookies.remove("access");
      Cookies.remove("refresh");
      Cookies.remove("user_email");
      Cookies.remove("user_role");
      setSuccesMessege("Successfully Has Been Logout.");
      history("/");
      setTimeout(() => {
        window.location.reload(true);
      }, [2000]);
    } catch (err) {
      setErrorMessege("Some Error Occur. Please Try again later.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };

  const [show, setShow] = useState(false);
  const [id, setId] = useState('')


  const idData = useGetMrIDByAccessTokenQuery(Cookies.get("access"));

  useEffect(()=>{
    if(idData!==undefined){
      if(idData==='fulfilled'){
        setId(id.data.id)
      }
    }
  },[Cookies.get("access")])


  const datas = useGetMemberRegistrationsByIdQuery(id);




  return (
    <header>
      <div className="header-top-bar">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12" style={{ textAlign: "start" }}>
              <ul className="top-bar-info list-inline-item pl-0 mb-0">
                <li className="list-inline-item">
                  <a href="mailto:info@nmsra.com">
                    <i className="icofont-support-faq mr-2"></i>
                    nmsra.kathmandu@gmail.com
                  </a>
                </li>
                <li className="list-inline-item">
                  <i className="icofont-location-pin mr-2"></i>Bagbazar,
                  Kathmandu, Nepal{" "}
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 res-display">
              <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
                <a href="tel:01-5332478">
                  <span>Call Now : </span>
                  <span className="h6">01-5332478, +977-9761662048</span>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-12" style={{ textAlign: "start" }}>
              <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
                {isAuth === true ? (
                  <>
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <li className="nav-item dropdown">
                          {
                            admin === 'admin' ?
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="dropdown02"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{
                                  fontSize: "15px",
                                  color: "white",
                                  fontWeight: "600",
                                }}
                              >
                                NMSRA ADMIN <i className="icofont-thin-down"></i>
                              </a> : <>
                                {
                                  datas !== undefined ?
                                    <>
                                      {
                                        datas.status === "fulfilled" ?
                                          <>
                                            <a
                                              className="nav-link dropdown-toggle"
                                              href="#"
                                              id="dropdown02"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              style={{
                                                fontSize: "15px",
                                                color: "white",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {datas.data.user.first_name} {datas.data.user.middle_name} {datas.data.user.last_name} <i className="icofont-thin-down"></i>
                                            </a>
                                          </> : null
                                      }
                                    </> : null
                                }
                              </>
                          }
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdown02"
                          >
                            {/* <li>
                              <Link
                                className="dropdown-item"
                                to="/my/id/card"
                              >
                                <i className="icofont-card mr-1" style={{ color: "black" }}></i>
                                My ID Card
                              </Link>
                            </li> */}
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/user/change/password"
                              >
                                <i className="icofont-key mr-1" style={{ color: "black" }}></i>
                                Change Password
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={Logout}
                                className="dropdown-item"
                              >
                                <i className="icofont-sign-in mr-1" style={{ color: "black" }}></i>
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </li>
                    </ul>
                    {/* <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="dropdown02"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{fontSize:'15px',color:"white",fontWeight: '600'}}
                    >
                      My Account <i className="icofont-thin-down"></i>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="dropdown02">
                      <li>
                        <Link to="#" onClick={Logout} className="dropdown-item">
                          <i className="icofont-sign-in mr-1"></i>
                          <span>Logout</span>
                        </Link>
                      </li>
                    </ul> */}
                  </>
                ) : (
                  <>
                    <Link to="/login" className="mr-2">
                      <i className="icofont-sign-in mr-1"></i>
                      <span>Login</span> <span>|</span> <span>Sign Up</span>
                    </Link>
                    {/* <Link
                      to="/login"
                      className="btn btn-main btn-round-full"
                    >
                      Sign Up
                    </Link> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navigation" id="navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="" className="img-fluid" />
          </Link>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarmain"
            aria-controls="navbarmain"
            aria-expanded={show === true ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={
              !show === true ? () => setShow(true) : () => setShow(false)
            }
          >
            <span className="icofont-navigation-menu"></span>
          </button>

          <div
            className={
              show === true
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse res-display"
            }
            id="navbarmain"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/about/organization"
                    id="dropdown02"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About Us <i className="icofont-thin-down"></i>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown02">
                    <li>
                      <Link className="dropdown-item" to="/about/organization">
                        About Organization
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/mission/vision">
                        Mission & Vision
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/message/president">
                        President's Message
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/executive/member">
                        NMSRA Kathmandu Executive Bodies
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/past/executive/member"
                      >
                        NMSRA Kathmandu Past President
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/central/past/president">
                        NMSRA Central Past President
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/office/staff">
                        Office Staff
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/life/time/member">
                        Lifetime Member
                      </Link>
                    </li>
                  </ul>
                </li>
              </li>
              <li className="nav-item">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdown02"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Events <i className="icofont-thin-down"></i>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown02">
                    <li>
                      <Link className="dropdown-item" to="/regular/event">
                        Regular Events
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/medical/event">
                        All Medical Events
                      </Link>
                    </li>
                  </ul>
                </li>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/notice">
                  Notice
                </Link>
              </li>
              <li className="nav-item">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdown02"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Activities <i className="icofont-thin-down"></i>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown02">
                    <li>
                      <Link className="dropdown-item" to="/NMSRA/activity">
                        NMSRA Activities
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/NMSRA/campaign">
                        NMSRA Campaign's
                      </Link>
                    </li>
                  </ul>
                </li>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">
                  Gallery
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Suggestion Box
                </Link>
              </li>
              {isAuth === true ? (
                <li className="nav-item">
                  <HashLink className="nav-link" to="/#book-a-appointment">
                    Book Appointment
                  </HashLink>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </header>
  );
};

export default Header;
