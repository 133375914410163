import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import Sidebar from "../../component/sidebar";
import Controls from "../../../../../components/forms/controls/Controls";
import Qr from "../../../../../static/image/qr_code.jpg";
import axios from "axios";
import { BASE_URL } from "../../../../../constant/baseURL";
import Cookies from "js-cookie";
import { useForm } from "../forms/useForm";
import {
  useAddMemberRegistrationMutation,
  useUpdateMemberRegistrationMutation,
} from "../../../../../api/memberRegistrationSlice";
import { useGetMrIDByAccessTokenQuery } from "../../../../../api/userSlice";
import { usePostPaymentMutation } from "../../../../../api/mrRegistrationSlice";

const PaymentPage = () => {
  return (
    <Box className="dashboard-full-background">
      <Grid container spacing={0}>
        <Grid item md={2} xs={12} sm={12}>
          <Sidebar />
        </Grid>
        <Grid item md={10} xs={12} sm={12} className="res-margin-design1" >
          <Header />
          <PaymentPageDesign />
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentPageDesign = () => {
  const initialFValues = {
    payment_details: [],
    year: "",
  };
  const { data } = useGetMrIDByAccessTokenQuery(Cookies.get("access"));

  const { values, handleInputChange } = useForm(initialFValues, true, false);

  const [updatePayment] = usePostPaymentMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (data.id) {
      formData.append("mr_id", data.id);
    }
    formData.append("year", values.year);
    formData.append("payment_details", values.payment_details);
    updatePayment(formData).then((res) => {

    });
  };
  return (
    <Box className="table-design-background ">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography className="dashboard-home-page-text">
            Membership Payment
          </Typography>
        </Grid>
      </Grid>
      <Box
        style={{
          padding: "1rem",
          paddinBottom: "2rem",
          paddingTop: "0rem",
          textAlign: "start",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box className="passwordbtn">
              <label className="emailtext">Select Payment Year*</label>
              <Controls.Select
                id="year"
                title="year"
                name={"year"}
                label={"Select Payment Year"}
                value={values.year}
                onChange={handleInputChange}
                options={[
                  { id: "2022", title: "2022" },
                  { id: "2023", title: "2023" },
                  { id: "2024", title: "2024" },
                  { id: "2025", title: "2025" },
                  { id: "2026", title: "2026" },
                  { id: "2027", title: "2027" },
                  { id: "2028", title: "2028" },
                  { id: "2029", title: "2029" },
                  { id: "2030", title: "2030" },
                  { id: "2031", title: "2031" },
                  { id: "2032", title: "2032" },
                  { id: "2033", title: "2033" },
                ]}
              />
            </Box>
            <Box className="passwordbtn">
              <label className="emailtext">Payment File*</label>
              <Controls.File
                name={"payment_details"}
                onChange={handleInputChange}
              />
            </Box>
            <Grid md={2} xs={3} item style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={Qr} alt="qr_code" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PaymentPage;
