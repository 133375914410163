import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";

import { useGetActiveKathmanduPresidentsQuery,
useAddKathmanduPresidentMutation,
useDeleteKathmanduPresidentMutation,
useGetKathmanduPresidentsByIdQuery,
useGetKathmanduPresidentsQuery, 
useUpdateKathmanduPresidentMutation} from "../../../../../api/kathmanduPresidentSlice";
import BlobToFile from "../forms/utils/blobToFile";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const KathmanduPresidentEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditKathmanduPresidentPagePages />
      </Grid>
    </Grid>
  );
};

const EditKathmanduPresidentPagePages = () => {
  const { id } = useParams();
  const kathmanduPresidents = useGetKathmanduPresidentsByIdQuery(id);
  const [File1, setFile1] = useState([]);
  const [updateKathmanduPresidentPage, setKathmanduPresidentPage] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    name: "",
    years: "",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  useEffect(() => {
    
    if (kathmanduPresidents.data) {
      if (kathmanduPresidents.data.image) {
        urlToFile(kathmanduPresidents.data.image, setFile1);
      }

      setInitialFValues({
        image: File1,
        name: kathmanduPresidents.data.name,
        years: kathmanduPresidents.data.years,
      });
    }
  }, [kathmanduPresidents.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editKathmanduPresidents] = useUpdateKathmanduPresidentMutation();

  useEffect(() => {
    handleImageUpload("image", File1);
  }, [File1]);


  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file1 = BlobToFile(values.image, "KathmanduPresident");
    if (file1.length !== 0) {
      formData.append("image", file1, "kathmanduPresident.jpg");
    }
    if(values.name)
      formData.append("name", values.name);
    if(values.years)
      formData.append("years", values.years);
    formData.append("id", id);
    editKathmanduPresidents(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Kathmandu Presidents Data.");
        setTimeout(() => {
          history("/dashboard/kathmandu-presidents");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Kathmandu Presidents Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="years"
                label="Years"
                value={values.years}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile1} aspectRatio={930 / 1280} />
            </Grid>
            <Grid item style={{ textAlign: "start", marginTop: "-3.2rem" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default KathmanduPresidentEditPage;
