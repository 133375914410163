import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { Link } from "react-router-dom";
import { Box, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";
import checkIfImageExists from "../forms/utils/checkIfImageExists";
import Controls from "../forms/controls/Controls";
import Pagination from '@mui/material/Pagination';
import AddCardIcon from '@mui/icons-material/AddCard';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const RedButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[500],
  margin: "0 auto",
  width: 100,
  "&:hover": {
    backgroundColor: red[700],
  },
  paddingTop: "7px",
  paddingBottom: "7px",
  fontSize: "12px",
  fontWeight: "600",
  borderRadius: "30px",
  cursor: "pointer",
}));

const GreenButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: green[500],
  width: 100,
  margin: "0 auto",
  textAlign: "center",
  "&:hover": {
    backgroundColor: green[700],
  },
  paddingTop: "7px",
  paddingBottom: "7px",
  fontSize: "12px",
  fontWeight: "600",
  borderRadius: "30px",
  cursor: "pointer",
}));

export default function BasicTable(props) {

  const {
    headers,
    data,
    isDelete,
    isEdit,
    isAdd,
    addColor,
    editColor,
    deleteColor,
    tableName,
    deleteConfirm,
    cardAvailable=false,
    activeSign = [],
    selectOption = [],
    confirmPush = false,
    handlePush,
    setCurrentPage,
    currentPage,
    totalPages,
    remarksOption = false,
    member = false
  } = props;

  const [open, setOpen] = useState(false);
  const [thisId, setThisId] = useState(null);
  const handleOpen = (e, id) => {
    setOpen(true);
    setThisId(id);
  };
  const handleClose = () => setOpen(false);

  const [thisId1, setThisId1] = useState(null);
  const handleOpen1 = (e, id) => {
    remarksOption.setOpen1(true);
    setThisId1(id);
  };
  const handleClose1 = () => remarksOption.setOpen1(false);

  const [open2, setOpen2] = useState(false);
  const [thisRemarks, setThisRemarks] = useState(null);
  const handleOpen2 = (e, id) => {
    setOpen2(true);
    setThisRemarks(id);
  };
  const handleClose2 = () => setOpen2(false);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const deleteConfirmHere = (e, id) => {
    deleteConfirm(e, id);
    setOpen(false);
  };

  const handleRemarks = (e) => {
    remarksOption.remarksSet(e.target.value);
  }

  const handleDelete = (e, id) => {
    remarksOption.remarksSet(null);
    remarksOption.onModalClick(e, id)
  }


  return (
    <>
      <TableContainer >
        <Table >
          <TableHead>
            <TableRow style={{ backgroundColor: '#ebedf7' }}>
              {headers.length === 0 ? (
                <></>
              ) : (
                <>
                  {headers.length === 1 ? (
                    <>
                      <TableCell align="center">
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {headers[0]}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Actions
                        </Typography>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      {headers.map((key, i) => (
                        <TableCell align="center">
                          <Typography
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {key}
                          </Typography>
                        </TableCell>
                      ))}
                      <>{selectOption.map((key) => (
                        <TableCell align="center">
                          <Typography
                            style={{ fontSize: "14px", fontWeight: "600" }}
                          >
                            {key['header']}
                          </Typography>
                        </TableCell>
                      ))}</>
                      {remarksOption.isRemarksShow ?
                        <TableCell align="center">
                          <Typography
                            style={{ fontSize: "15px", fontWeight: "600" }}
                          >
                            Remarks
                          </Typography>
                        </TableCell> : <></>}
                      <TableCell align="center">
                        <Typography
                          style={{ fontSize: "15px", fontWeight: "600" }}
                        >
                          Actions
                        </Typography>
                      </TableCell>
                      {member === true ?
                        <TableCell align="center">
                          <Typography
                            style={{ fontSize: "15px", fontWeight: "600" }}
                          >
                            Members Card
                          </Typography>
                        </TableCell> : null
                      }

                    </>
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {headers.map((key) => (
                  <>
                    {activeSign.includes(key) ? (
                      <TableCell align="center">
                        {row[key] ? (
                          <GreenButton>True</GreenButton>
                        ) : (
                          <RedButton>False</RedButton>
                        )}
                      </TableCell>
                    ) : (
                      <>
                        {row[key] ? (
                          <>
                            {checkIfImageExists(row[key]) ? (
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"
                              >
                                <img className="table-image" src={row[key]}></img>
                              </TableCell>
                            ) : (
                              <TableCell
                                align="center"
                                component="th"
                                scope="row"

                              >
                                <Typography style={{ fontSize: "15px", color: "black" }}>{row[key]}</Typography>
                              </TableCell>
                            )}
                          </>
                        ) : (
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                          ></TableCell>
                        )}
                      </>
                    )}

                    <></>
                  </>
                ))}
                {selectOption.map((key) => (
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <Controls.Select
                      onChange={(e) => key['onChange'](e, row.id)}
                      options={key['options']}
                      value={row[key['header']]}
                      name={key['name']} />
                  </TableCell>
                ))}

                {remarksOption['isRemarksShow'] ?
                  <TableCell align="center">
                    {row['Remarks'] ?
                      <>
                        <Typography onClick={(e) => handleOpen2(e, row['Remarks'])}>View</Typography>
                        <DeleteSharpIcon
                          sx={{ m: 0.5 }}
                          style={{ color: deleteColor }}
                          onClick={(e) => handleDelete(e, row["id"])}
                        /></>
                      : <>
                        {remarksOption['isRemarksAdd'] ?
                          <AddCircleOutlineSharpIcon
                            sx={{ m: 0.5 }}
                            style={{ color: addColor }}
                            onClick={(e) => handleOpen1(e, row["id"])}
                          /> : <></>}</>}
                  </TableCell> :
                  <></>}


                {isDelete || isEdit || isAdd || confirmPush ? (
                  <TableCell align="center">
                    {isAdd ? (
                      <Link to={`/dashboard/${tableName}/view/${row["id"]}`}>
                        <AddCircleOutlineSharpIcon
                          sx={{ m: 0.5 }}
                          style={{ color: addColor }}
                        />
                      </Link>
                    ) : (
                      <></>
                    )}
                    {isEdit ? (
                      <Link to={`/dashboard/${tableName}/edit/${row["id"]}`}>
                        <ModeEditSharpIcon
                          sx={{ m: 0.5 }}
                          style={{ color: editColor }}
                        />
                      </Link>
                    ) : (
                      <></>
                    )}
                    {isDelete ? (
                      <a onClick={(e) => handleOpen(e, row["id"])}>
                        <DeleteSharpIcon
                          sx={{ m: 0.5 }}
                          style={{ color: deleteColor }}
                        />
                      </a>
                    ) : (
                      <></>
                    )}
                    {confirmPush ? (
                      <a onClick={(e) => handlePush(e, row["id"])}>
                        <ModeEditSharpIcon
                          sx={{ m: 0.5 }}
                          style={{ color: deleteColor }}
                        />
                      </a>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                ) : (
                  <></>
                )}
                {cardAvailable ?
                  <TableCell align="center">
                    <Link to={`/dashboard/users/card/${row["id"]}`}>
                      <AddCardIcon
                        sx={{ m: 0.5 }}
                        style={{ color: editColor }}
                      />
                    </Link>

                  </TableCell> : <></>}

              </TableRow>
            ))}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  className="modal-text-design"
                  variant="h6"
                  component="h2"

                >
                  Do you want to delete?
                </Typography>
                <button
                  className="yes-button"
                  onClick={(e) => deleteConfirmHere(e, thisId)}
                >
                  Yes
                </button>
                <button className="no-button" onClick={handleClose}>
                  No
                </button>
              </Box>
            </Modal>
            <Modal
              open={remarksOption.open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  className="modal-text-design"
                  variant="h6"
                  component="h2"

                >
                  Add Remarks
                </Typography>
                <Controls.Input
                  name="remarks"
                  onChange={handleRemarks} />
                <button
                  className="yes-button"
                  onClick={(e) => { remarksOption['onModalClick'](e, thisId1) }}
                >
                  Yes
                </button>
                <button className="no-button" onClick={handleClose1}>
                  No
                </button>
              </Box>
            </Modal>
            <Modal
              open={open2}
              onClose={handleClose2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  className="modal-text-design"
                  variant="h6"
                  component="h2"

                >
                  {thisRemarks}
                </Typography>
                <button className="no-button" onClick={handleClose2}>
                  Close
                </button>
              </Box>
            </Modal>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box> */}
    </>
  );
}
