import React, { useState } from "react";
import { Button as MuiButton, makeStyles } from '@material-ui/core';
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
  spinner: {
    color: "#fff",
    marginLeft: theme.spacing(1),
  },
}));

export default function Button(props) {
  const { text, size="small", color, variant, onClick, backgroundColor } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClick = async (e) => {
    setLoading(true);
    try {
      await onClick(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MuiButton
      variant={variant || "contained"}
      size={size}
      color={color || "primary"}
      onClick={handleClick}
      className="contact-us-button"
      classes={{ root: classes.root, label: classes.label }}
      disabled={loading}
    >
      {text}
      {loading && <CircularProgress size={24} className={classes.spinner} />}
    </MuiButton>
  );
}
