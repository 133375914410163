import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAddContactMutation } from "../../../api/contactSlice";
import { useForm } from "../../dashboard/component/pages/forms/useForm";
import Footer from "../component/footer";
import Header from "../component/header";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactPage />
      <Footer />
    </>
  );
};

const ContactPage = () => {
  const initialFValues = {
    full_name: "",
    email: "",
    contact_no: "",
    message: "",
  };
  const [addContact] = useAddContactMutation();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInput,
    resetForm,
    valueArray,
  } = useForm(initialFValues, true);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    formData.append("contact_no", values.contact_no);
    formData.append("message", values.message);
    addContact(formData)
      .then((res) => {
        setSuccesMessege("Thanks for Contacting Us.");
        setTimeout(() => {
          window.location.reload();
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <div style={{ textAlign: "start" }}>
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Suggestion Box</span>
                <h1 className="text-capitalize mb-5 text-lg">Get in Touch</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section contact-info pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-live-support"></i>
                <h5>Call Us</h5>
                015332478
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-support-faq"></i>
                <h5>Email Us</h5>
                nmsra.kathmandu@gmail.com
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-location-pin"></i>
                <h5>Location</h5>
                Bagbazar, Kathmandu, Nepal
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-wrap section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="text-md mb-2">Suggestion Box</h2>
                <div className="divider mx-auto my-4"></div>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <form
                id="contact-form"
                className="contact__form "
                // method="post"
                // action="mail.php"
              >
                <div className="row">
                  <div className="col-12">
                    <div
                      className="alert alert-success contact__msg"
                      style={{ display: "none" }}
                      role="alert"
                    >
                      Your message was sent successfully.
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="full_name"
                        id="full_name"
                        type="text"
                        className="form-control"
                        placeholder="Your Full Name"
                        onChange={handleInputChange}
                        value={values.full_name}
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="subject"
                        id="subject"
                        type="text"
                        className="form-control"
                        placeholder="Your Query Topic"
                        onChange={handleInputChange}
                        value={values.contact_no}
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="contact_no"
                        id="contact_no"
                        type="text"
                        className="form-control"
                        placeholder="Your Contact Number"
                        onChange={handleInputChange}
                        value={values.contact_no}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Your Email Address"
                        onChange={handleInputChange}
                        value={values.email}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group-2 mb-4">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="8"
                    placeholder="Your Suggestion"
                    onChange={handleInputChange}
                    value={values.message}
                  ></textarea>
                </div>

                <div className="text-center">
                  <input
                    className="btn btn-main btn-round-full"
                    name="submit"
                    type="submit"
                    value="Send Suggestion"
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default Contact;
