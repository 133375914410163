import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constant/baseURL";
import Footer from "../component/footer";
import Header from "../component/header";
import SignUpPage from "./signupPage";
import { useAddMemberRegistrationMutation } from "../../../api/memberRegistrationSlice";
import BlobToFile from "../../../components/forms/utils/blobToFile";
import { useForm1 } from "../../../components/forms/useForm";
import moment from "moment/moment";
import { useGetLFNoByLFNoQuery } from "../../../api/lfNoSlice";
import Cookies from "js-cookie";

const Signup = () => {
  return (
    <>
      <Header />
      <SignupPage />
      <Footer />
    </>
  );
};

const SignupPage = () => {
  const [File1, setFile1] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [Accept, setAccept] = useState(false);
  let today = new Date();
  const [isSamePermanent, setIsSamePermanent] = useState(false);
  const [initialFValues, setInitialFValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    permanent_street: "",
    permanent_ward_no: 0,
    permanent_local_level: "",
    permanent_district: "",
    permanent_country: "",
    temporary_street: "",
    temporary_ward_no: 0,
    temporary_local_level: "",
    temporary_district: "",
    contact_no: "",
    blood_group: "",
    name_of_company: "",
    date_of_joining: today,
    division: "",
    current_position: "",
    territory: "",
    academic_degree: "",
    academic_faculty: "",
    appointment_letter: [],
    academic_transcript: [],
    profile_pic: [],
    confirmation: false,
    password: "",
    cpassword: "",
    payment_details: [],
    emergency_contact: "",
    year: "",
    lf_no: "",
    nmsra_no: "",
  });


  const { values, handleImageUpload, handleInputChange } = useForm1(
    initialFValues,
    true,
    false,
    false
  );
  console.log(values)

  const history = useNavigate();

  const [addMemberRegistration] = useAddMemberRegistrationMutation();

  useEffect(() => {
    handleImageUpload("profile_pic", File1);
  }, [File1]);

  const acceptTerms = (e) => {
    setAccept(e.target.value);
  };

  const lfNoAvailable = useGetLFNoByLFNoQuery(values.lf_no);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(values.blood_group === null || values.blood_group === "" || values.blood_group === undefined)
    {
      setErrorMessege("Entered blood group is not Available in our Database.");
      Cookies.set('user_info', JSON.stringify(values))
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        return 0;
    }
    const formData = new FormData();
    var file1 = BlobToFile(values.profile_pic, "homePage1");
    if (file1.length !== 0) {
      formData.append("profile_pic", file1, "profile_pic.jpg");
    }
    if (values.payment_details) {
      formData.append("payment_details", values.payment_details);
    }
    for (var key in values) {
      if (key !== "profile_pic") {
        formData.append(key, values[key]);
      }
      if (key == "date_of_joining") {
        formData.append(key, moment(values[key]).format("YYYY-MM-DD"));
      }
      if (
        (key === "academic_transcript" || key === "appointment_letter") &&
        values[key] === undefined
      ) {
        formData.append(key, []);
      }
      if (isSamePermanent) {
        formData.append("temporary_street", values.permanent_street);
        formData.append("temporary_local_level", values.permanent_local_level);
        formData.append("temporary_ward_no", values.permanent_ward_no);
        formData.append("temporary_district", values.permanent_district);
      }
    }
    if (Accept) {
      if(values.nmsra_no=="new")
      {
        addMemberRegistration(formData)
        .then((res) => {
          if (res.data) {
            setSuccesMessege("Successfully Registered.");
            setTimeout(() => {
              history("/login");
            }, [3000]);
          } else {
            Cookies.set('user_info', JSON.stringify(values))
            setErrorMessege(res.error.data.error);
            setTimeout(() => {
              setErrorMessege(null);
            }, [3000]);
      }})}
      else{
      if (lfNoAvailable.status === "fulfilled") {
        if (lfNoAvailable.data.results.length === 0) {
          setErrorMessege("Entered Lf Number is not Available in our Database.");
         Cookies.set('user_info', JSON.stringify(values)) 
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        }
        if (lfNoAvailable.data.results[0].nmsra_no == values.nmsra_no) {
          addMemberRegistration(formData)
            .then((res) => {
              if (res.data) {
                setSuccesMessege("Successfully Registered.");
                setTimeout(() => {
                  history("/login");
                }, [3000]);
              } else {
                
                setErrorMessege(res.error.data.error);
                Cookies.set('user_info', JSON.stringify(values))
                setTimeout(() => {
                  setErrorMessege(null);
                }, [3000]);
                if (res.error.data.user) {
                  setErrorMessege(res.error.data.user.email);
                  setTimeout(() => {
                    setErrorMessege(null);
                  }, [3000]);
                }
              }
            })
        } else {
          setErrorMessege("Your LF No. and NMSRA MEMBERSHIP NO. don't match");
          Cookies.set('user_info', JSON.stringify(values))
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        }
      }
    else {
      setErrorMessege("Terms and condition not accepted");
      Cookies.set('user_info', JSON.stringify(values))
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  }
  }};
  return (
    <Box className="signinmargin res-padding" style={{ textAlign: "start" }}>
      <Grid item xs={12} md={8} className="signinauto">
        <Grid container>
          <Grid item xs={12} md={5} className="leftcolor res-display">
            <Box className="signinnayamargin1">
              <Typography className="accounttext">
                Already Have an Account?
              </Typography>
              <Link to="/login">
                <button className="signupnewbtn">Login</button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={7} xs={12} className="signinleft">
            <Box>
              <Typography className="signintext">Sign Up</Typography>
            </Box>
            <SignUpPage
              acceptTerms={acceptTerms}
              handleInputChange={handleInputChange}
              values={values}
              handleSubmit={handleSubmit}
              handleFileUpload={handleImageUpload}
              setImage={setFile1}
              setIsSamePermanent={setIsSamePermanent}
              isSamePermanent={isSamePermanent}
            />
            {SuccessMessege === null ? null : (
              <Box className="successmessege">
                <Typography className="messegefonts">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="messegeicon"
                  />
                  {SuccessMessege}
                </Typography>
              </Box>
            )}
            {ErrorMessege === null ? null : (
              <Box className="errormessege">
                <Typography className="messegefonts">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="messegeicon"
                  />
                  {ErrorMessege}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;
