import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../static/image/logo.png";

const Footer = () => {
  return (
    <footer className="footer section gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mr-auto col-sm-6">
            <div className="widget mb-5 mb-lg-0" style={{ textAlign: "start" }}>
              <div className="logo mb-4">
                <img src={Logo} alt="" className="img-fluid" />
              </div>
              <p>
                An organized association of workers,professionals formed to
                protect their rights.
              </p>

              <ul className="list-inline footer-socials mt-4">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/nmsraktm" target="_blank">
                    <i className="icofont-facebook"></i>
                  </a>
                </li>
                {/* <li className="list-inline-item">
                  <a
                    target="_blank"
                    href="https://twitter.com/search?q=nmsra&src=typed_query"
                  >
                    <i className="icofont-twitter"></i>
                  </a>
                </li> */}
                <li className="list-inline-item">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/nmsrakathmandu/"
                  >
                    <i className="icofont-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6">
            <div className="widget mb-5 mb-lg-0" style={{ textAlign: "start" }}>
              <h4 className="text-capitalize mb-3">About</h4>
              <div className="divider mb-4"></div>

              <ul className="list-unstyled footer-menu lh-35">
                <li>
                  <Link to="/about/organization">About NMSRA </Link>
                </li>
                <li>
                  <Link to="/mission/vision">Mission & Vision</Link>
                </li>
                <li>
                  <Link to="/message/president">President's Message</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6">
            <div className="widget mb-5 mb-lg-0" style={{ textAlign: "start" }}>
              <h4 className="text-capitalize mb-3">Support</h4>
              <div className="divider mb-4"></div>

              <ul className="list-unstyled footer-menu lh-35">
                <li>
                  <Link to="/regular/event">Events</Link>
                </li>
                <li>
                  <Link to="/notice">News</Link>
                </li>
                <li>
                  <Link to="/contact">Contact </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="widget widget-contact mb-5 mb-lg-0"
              style={{ textAlign: "start" }}
            >
              <h4 className="text-capitalize mb-3">Get in Touch</h4>
              <div className="divider mb-4"></div>

              <div className="footer-contact-block mb-4">
                <div className="icon d-flex align-items-center">
                  <i className="icofont-email mr-3"></i>
                  <span className="h6 mb-0">Support Available for 24/7</span>
                </div>
                <h4 className="mt-2">
                  <a
                    href="mailto:nmsra.kathmandu@gmail.com"
                    style={{ fontSize: "17.5px" }}
                  >
                    nmsra.kathmandu@gmail.com
                  </a>
                </h4>
              </div>

              <div className="footer-contact-block">
                <div className="icon d-flex align-items-center">
                  <i className="icofont-support mr-3"></i>
                  <span className="h6 mb-0">Sun to Fri : 10:00 - 18:00</span>
                </div>
                <h4 className="mt-2">
                  <a href="tel:01-5332478" style={{ fontSize: "17.5px" }}>
                    01-5332478,+977-9761662048
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-btm py-4 mt-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="copyright" style={{ textAlign: "start" }}>
                &copy; Copyright Reserved to{" "}
                <span className="text-color">NMSRA</span> by{" "}
                <a href="https://www.developergd.com/" target="_blank">
                  G. & D. Developer Pvt. Ltd.
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="subscribe-form text-lg-right mt-5 mt-lg-0">
                <form action="#" className="subscribe">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email address"
                  />
                  <a href="#" className="btn btn-main-2 btn-round-full">
                    Subscribe
                  </a>
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <a className="backtop js-scroll-trigger" href="#top">
                <i className="icofont-long-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
