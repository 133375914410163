import React, { useEffect, useState } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Image1 from "../../../static/image/about/img-1.jpg";
import Image2 from "../../../static/image/about/img-2.jpg";
import Image3 from "../../../static/image/about/img-3.jpg";
import Image4 from "../../../static/image/team/test-thumb1.jpg";
import Image5 from "../../../static/image/about/1.png";
import { Link } from "react-router-dom";
import { useGetHomePagesQuery } from "../../../api/homePageSlice";
import { useGetActivePartnersQuery } from "../../../api/partnerSlice";
import { useGetTestimonialsQuery } from "../../../api/testimonialSlice";
import { useGetActiveHomePagesQuery } from "../../../api/homePageSlice";
import { useGetActiveLandingPagesQuery } from "../../../api/landingPageSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box } from "@mui/system";
import HomePageSkeleton from "./skeletons/homePageSkeleton";
import Cookies from "js-cookie";
import Controls from "../../dashboard/component/pages/forms/controls/Controls";
import { useGetActiveHospitalHolidaysQuery } from "../../../api/hospitalHolidaySlice";
import { BASE_URL } from "../../../constant/baseURL";
import axios from "axios";
import { useGetHospitalsQuery } from "../../../api/hospitalSlice";
import { Grid, Modal, Typography } from "@mui/material";
import {
  useAddHospitalBookingMutation,
  useCheckHospitalBookingMutation,
} from "../../../api/hospitalBookingSlice";
import { HashLink } from "react-router-hash-link";
import { CommentsDisabledOutlined } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useGetAllNewsQuery, useGetNewsQuery } from "../../../api/newSlice";
import { Carousel } from "react-bootstrap";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useGetStatRegistrationsQuery } from "../../../api/statsSlice";
import { useGetExcludedHospitalDatesMutation } from "../../../api/hospitalBookingSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  height: 650,
};

const HomePage = () => {
  return (
    <>
      <Header />
      <HomePageDesign />
      <Footer />
    </>
  );
};

const HomePageDesign = ({ url }) => {
  const landingPage = useGetActiveLandingPagesQuery();

  const testimonial = useGetTestimonialsQuery();
  const activeHomePages = useGetActiveHomePagesQuery();
  const activePartners = useGetActivePartnersQuery();

  const [isAuth, setIsAuth] = useState(false);

  const access = Cookies.get("access");
  const refresh = Cookies.get("refresh");
  const email = Cookies.get("user_emailemail");
  const TOKEN_KEY = { access, refresh, email };
  const [statistics, setStatistics] = useState({
    "number_of_hospital": 0,
    "number_of_member": 0,
    "years_active": 0,
    "no_of_unit": 0
  })

  const stats = useGetStatRegistrationsQuery();
  useEffect(() => {
    if (stats.status == "fulfilled") {
      setStatistics(stats.data);
    }

  }, [stats.status])

  useEffect(() => {
    if (
      TOKEN_KEY["access"] == null &&
      TOKEN_KEY["refresh"] == null &&
      TOKEN_KEY["user_email"] == null
    ) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, []);
  const [hospitalID, setHospitalID] = useState(null);
  const [disableDates, setDisableDates] = useState([]);
  const hospitalData = useGetHospitalsQuery();
  const hospitalHoliday = useGetActiveHospitalHolidaysQuery(hospitalID);
  const [accompanistNo, setAccompanistNo] = useState(0);
  const [selectedDates, setSelectedDates] = useState(new Date());
  const [hospitalBookings, { error }] = useAddHospitalBookingMutation();
  const [checkHospitalBookings] = useCheckHospitalBookingMutation();

  const [bookingResponse, setBookingResponse] = useState({
    is_bookable: false,
    current_bookings: 0,
    error: "No data selected",
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  useEffect(() => {
    if (hospitalHoliday.status === "fulfilled") {
      setDisableDates(hospitalHoliday.data.date);
    }
  }, [hospitalHoliday, hospitalID]);

  let hospitalList = [];
  if (hospitalData.status === "fulfilled") {
    hospitalData.data.ids.forEach((key) => {
      hospitalList.push({
        id: key,
        title: hospitalData.data.entities[key].name,
      });
    });
  }

  const [excludedHospitalDates] = useGetExcludedHospitalDatesMutation();
  const [excludedDate, setExcludedDate] = useState();

  const accompanistNumber = (e) =>{
    setAccompanistNo(e.target.value);
  }
  const selectHospital = (e) => {
    setHospitalID(e.target.value);
    excludedHospitalDates(
      {
        hospital_id: e.target.value,
        user_email: Cookies.get('user_email')
      }
    ).then((res)=>{
      setExcludedDate(res.data)
    })
  };
  const onDateSelect = (e) => {
    setSelectedDates(e.target.value);
    checkHospitalBookings({
      hospital_id: hospitalID,
      date: e.target.value,
      user: Cookies.get("access"),
    })
      .unwrap()
      .then((res) => {
        setBookingResponse(res.data);
        setSuccesMessege("Date is Valid.");
        setTimeout(() => {
          setSuccesMessege(null);
        }, [3000]);
      })
      .catch((error) => {
        setErrorMessege(error.data.error);
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  const confirmDate = (e) => {
    e.preventDefault();
    if (bookingResponse.is_bookable) {
      hospitalBookings({
        hospital_date_id: bookingResponse.hospital_date_id,
        mr_id: Cookies.get("access"),
        accompanist_number: accompanistNo
      })
        .then((res) => {
          setSuccesMessege("Booking request has been send. You will receive mail after your bookings have been approved!!!");
          setTimeout(() => {
            setSuccesMessege(null);
          }, [2000]);
        })
        .catch((err) => {
          setErrorMessege(error.data.error);
          setTimeout(() => {
            setErrorMessege(null);
          }, [2000]);
        });
    } else {
      // setErrorMessege("Token has been Expired.");
      // setTimeout(() => {
      //   setErrorMessege(null);
      // }, [2000]);
    }
  };
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const modalClosed = Cookies.get("modalClosed") === "true";
    setOpen(!modalClosed);
  }, []);

  const PdfData = useGetAllNewsQuery();

  const [NoticePdf, setNoticePdf] = useState("");

  useEffect(() => {
    if (PdfData.data) {
      if (PdfData.data[0]) {
        setNoticePdf(PdfData.data[0]);
      }
    }
  }, [PdfData.data]);

 

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div style={{ textAlign: "start" }}>
      <div className="res-display">
      </div>
      {landingPage !== undefined ? (
        <>
          <>
            {landingPage.status === "fulfilled" ? (
              <>
                {landingPage.data.ids.length === 0 ? (
                  <Box>
                    <SkeletonTheme color="grey" highlightColor="#444">
                      <p>
                        <Skeleton height={550} width={"100%"} count={1} />
                      </p>
                    </SkeletonTheme>
                  </Box>
                ) : (
                  <Carousel indicators={false} controls={false} interval={4000}>
                    {landingPage.data.ids.map((key) => (
                      <Carousel.Item key={key}>
                        <section
                          className="banner"
                          style={{
                            backgroundImage: `url(${landingPage.data.entities[key].image1})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          <Box className="new-background-color">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-6 col-md-12 col-xl-7">
                                  <div className="block">
                                    <div className="divider mb-3"></div>
                                    <span
                                      className="text-uppercase text-sm letter-spacing "
                                      style={{
                                        color: "black",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {landingPage.data.entities[key].title}
                                    </span>
                                    <h1 className="mb-3 mt-3">
                                      {
                                        landingPage.data.entities[key]
                                          .description
                                      }
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Box>
                        </section>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
              </>
            ) : (
              <Box>
                <SkeletonTheme color="grey" highlightColor="#444">
                  <p>
                    <Skeleton height={550} width={"100%"} count={1} />
                  </p>
                </SkeletonTheme>
              </Box>
            )}
          </>
        </>
      ) : null}
      <section className="features" style={{ zIndex: 2 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="feature-block d-lg-flex">
                <div className="feature-item mb-5 mb-lg-0">
                  <div className="feature-icon mb-4">
                    <i className="icofont-user"></i>
                  </div>
                  <span>Join Us</span>
                  <h4 className="mb-3">Become NMSRA Member</h4>
                  {/* <p className="mb-4">
                    Get All time support for emergency.We have introduced the
                    principle of family medicine.
                  </p> */}
                  {isAuth === true ? (
                    <Link
                      to="/dashboard/home"
                      className="btn btn-main btn-round-full"
                    >
                      Go to Dashboard
                    </Link>
                  ) : <Link
                    to="/login"
                    className="btn btn-main btn-round-full"
                  >
                    Become a Member
                  </Link>}
                </div>

                <div className="feature-item mb-5 mb-lg-0">
                  <div className="feature-icon mb-4">
                    <i className="icofont-ui-clock"></i>
                  </div>
                  <span>Timing schedule</span>
                  <h4 className="mb-3">Working Hours</h4>
                  <ul className="w-hours list-unstyled">
                    <li className="d-flex justify-content-between">
                      Sun - Fri : <span>11:00 AM -4:00 PM</span>
                    </li>
                  </ul>
                </div>

                <div className="feature-item mb-5 mb-lg-0">
                  <div className="feature-icon mb-4">
                    <i className="icofont-calendar"></i>
                  </div>
                  <span>Appointment Booking</span>
                  <h4 className="mb-3">Book an Appointment</h4>
                  {/* <p className="mb-4">
                    Get All time support for emergency.We have introduced the
                    principle of family medicine.Get Conneted with us for any
                    urgency .
                  </p> */}
                  {isAuth === true ? (
                    <HashLink
                      to="/dashboard/book-my-hospitals"
                      className="btn btn-main btn-round-full"
                    >
                      Book an Appointment
                    </HashLink>
                  ) : <Link
                    to="/login"
                    className="btn btn-main btn-round-full"
                  >
                    Book an Appointment
                  </Link>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section about">
        <div className="container">
          {activeHomePages !== undefined ? (
            <>
              {activeHomePages.status === "fulfilled" ? (
                <>
                  {activeHomePages.data.ids.length === 0 ? (
                    <HomePageSkeleton />
                  ) : (
                    <>
                      {activeHomePages.data.ids.map((key) => (
                        <div className="row align-items-center" key={key}>
                          <div className="col-lg-4 col-sm-6">
                            <div className="about-img">
                              <img
                                src={activeHomePages.data.entities[key].image1}
                                alt=""
                                className="img-fluid"
                              />
                              <img
                                src={activeHomePages.data.entities[key].image2}
                                alt=""
                                className="img-fluid mt-4"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6">
                            <div className="about-img mt-4 mt-lg-0">
                              <img
                                src={activeHomePages.data.entities[key].image3}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="about-content pl-4 mt-4 mt-lg-0">
                              <h2 className="title-color">
                                {activeHomePages.data.entities[key].title}
                              </h2>
                              <p className="mt-4 mb-5">
                                {activeHomePages.data.entities[key].subtitle}
                              </p>
                              {isAuth === false ? (
                                <Link
                                  to="/signup"
                                  target="_blank"
                                  className="btn btn-main-2 btn-icon btn-round-full"
                                  style={{ color: "white" }}
                                >
                                  Become a Member{" "}
                                  <i className="icofont-simple-right ml-2  "></i>
                                </Link>
                              ) : (
                                <Link
                                  to="/dashboard/home"
                                  className="btn btn-main-2 btn-icon btn-round-full"
                                  style={{ color: "white" }}
                                >
                                  Go to Dashboard{" "}
                                  <i className="icofont-simple-right ml-2  "></i>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  <HomePageSkeleton />
                </>
              )}
            </>
          ) : (
            <>
              <HomePageSkeleton />
            </>
          )}
        </div>
      </section>
      <section className="cta-section ">
        <div className="container">
          <div className="cta position-relative">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-doctor"></i>
                  <span className="h3">{statistics.number_of_hospital}</span>
                  <p>Number of Privilege Hospital</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-flag"></i>
                  <span className="h3">{statistics.number_of_member}</span><p>Number of Member</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-badge"></i>
                  <span className="h3">{statistics.years_active}</span><p>Year Active</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-globe"></i>
                  <span className="h3">{statistics.no_of_unit}</span>
                  <p>Total Unit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isAuth === true ? (
        <section className="section appoinment">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 ">
                <div className="appoinment-content">
                  <img src={Image3} alt="" className="img-fluid" />
                  <div className="emergency">
                    <h2 className="text-lg">
                      <i className="icofont-phone-circle text-lg"></i>01-5332478
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 " id="book-a-appointment">
                <div className="appoinment-wrap mt-5 mt-lg-0">
                  <h2 className="mb-2 title-color">Book an Appointment</h2>
                  <p className="mb-4">Book your Appointment</p>
                  <form
                    id="#"
                    className="appoinment-form"
                    method="post"
                    action="#"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
                        <Controls.Select
                          name="hospital"
                          label="Select Hospital"
                          onChange={selectHospital}
                          options={hospitalList}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controls.DatePickers
                          buttonText="Select Booking Date"
                          name="date"
                          onChange={onDateSelect}
                          excludeDates={Array.from(disableDates).concat(excludedDate)}
                          selectedDate={selectedDates}
                        // excludeDates={disableDates}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{marginTop:'5px'}}>
                      <Controls.Select
                          name="accompanist_number"
                          label="Manager Number"
                          onChange={accompanistNumber}
                          options={[
                            {id: 0, title: "0"},
                            {id: 1, title: '1'},
                            {id: 2, title: '2'}
                          ]}
                        />
                      </Grid>
                    </Grid>

                    <a
                      className="btn btn-main btn-round-full mt-4"
                      onClick={(e) => confirmDate(e)}
                      style={{ color: "white" }}
                    >
                      Book An Appointment{" "}
                      <i className="icofont-simple-right ml-2  "></i>
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <section className="section testimonial-2 gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2>NMSRA Testimonials</h2>
                <div className="divider mx-auto my-4"></div>
                <p>These are NMSRA Testimonials</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 testimonial-wrap-2">
              {testimonial !== undefined ? (
                <>
                  {testimonial.status === "fulfilled" ? (
                    <>
                      {testimonial.data.ids.length === 0 ? (
                        <Box>
                          <SkeletonTheme color="grey" highlightColor="#444">
                            <p>
                              <Skeleton height={245} width={"100%"} count={1} />
                            </p>
                          </SkeletonTheme>
                        </Box>
                      ) : (
                        <>
                          {testimonial.data.ids.map((key) => (
                            <div
                              className="testimonial-block style-2  gray-bg"
                              key={key}
                            >
                              <i className="icofont-quote-right"></i>

                              <div className="testimonial-thumb">
                                <img
                                  src={
                                    testimonial.data.entities[key].client_photo
                                  }
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>

                              <div className="client-info ">
                                <h4>Amazing service!</h4>
                                <span>
                                  {testimonial.data.entities[key].client_name}
                                </span>
                                <p>
                                  {
                                    testimonial.data.entities[key]
                                      .client_testimonial
                                  }
                                </p>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <Box>
                      <SkeletonTheme color="grey" highlightColor="#444">
                        <p>
                          <Skeleton height={245} width={"100%"} count={1} />
                        </p>
                      </SkeletonTheme>
                    </Box>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <section className="section clients">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2>Partners who support us</h2>
                <div className="divider mx-auto my-4"></div>
                <p>These are our partners who help NMSRA.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row clients-logo">
            {activePartners !== undefined ? (
              <>
                {activePartners.status === "fulfilled" ? (
                  <>
                    {activePartners.data.ids.length === 0 ? (
                      <>
                        <Box className="col-lg-2">
                          <div className="client-thumb">
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={170}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </div>
                        </Box>
                        <Box className="col-lg-2">
                          <div className="client-thumb">
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={170}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </div>
                        </Box>
                        <Box className="col-lg-2">
                          <div className="client-thumb">
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={170}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </div>
                        </Box>
                        <Box className="col-lg-2">
                          <div className="client-thumb">
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={170}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </div>
                        </Box>
                        <Box className="col-lg-2">
                          <div className="client-thumb">
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={170}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </div>
                        </Box>
                        <Box className="col-lg-2">
                          <div className="client-thumb">
                            <SkeletonTheme color="grey" highlightColor="#444">
                              <p>
                                <Skeleton
                                  height={170}
                                  width={"100%"}
                                  count={1}
                                />
                              </p>
                            </SkeletonTheme>
                          </div>
                        </Box>
                      </>
                    ) : (
                      <>
                        {activePartners.data.ids.map((key) => (
                          <div className="col-lg-2" key={key}>
                            <div className="client-thumb">
                              <img
                                src={
                                  activePartners.data.entities[key]
                                    .partner_image
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default HomePage;
